import polpoApi from '@/api/polpoApi';

export default {
  checkFile: async (docType, docId, parameters) => polpoApi.getFileMetadata('file/check', docType, docId, parameters),
  getFileInfo: async (docType, docId, parameters) => polpoApi.getFileMetadata('file/info', docType, docId, parameters),
  getFile: async (docType, docId, parameters, timeout) => polpoApi.getFileData('file', docType, docId, parameters, timeout),

  // TODO it would be better if getFileStreamUrl is also handled through polpoApi:
  getFileStreamUrl: (docType, docId, parameters) => `${process.env.VUE_APP_HOST}/file/stream/${docType}/${docId}?mimeType=${parameters.mimeType}&filename=${parameters.filename}`,
};
