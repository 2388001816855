import polpoApi from '@/api/polpoApi';

export default {
  getById: async (id, parameters) => polpoApi.postById('eu/document', id, parameters),
  getTimelineItems: async (searchParameters) => {
    const {
      query,
      groupingDetails: { name: groupingName },
    } = searchParameters;

    if (query.trim() === '') {
      // eslint-disable-next-line no-console
      console.error('Invalid empty query! Not sending request to eu/document!');
      return null;
    }
    if (!groupingName) {
      // eslint-disable-next-line no-console
      console.error('Invalid missing grouping name! Not sending request to eu/document!');
      return null;
    }

    return polpoApi.post('eu/timeline', searchParameters);
  },
  getRelated: async (refs) => polpoApi.post('eu/related', refs),
};
