/*
 * This file holds all configurable settings, filters and preferences for the Polpo app
 */

export default {

  googleAnalyticsDevelopment: 'UA-99320682-2',
  googleAnalyticsProduction: 'UA-99320682-3',
  // Todo: only enable production when ready, ask Caspar
  // googleAnalyticsProduction: 'UA-99320682-3',

  // Default minimal matching percentage
  defaultMinimalMatchPercentage: 40,

  // Default width drawer left
  minDrawerLeftWidth: 128,
  defaultDrawerLeftWidth: 296,
  maxDrawerLeftWidth: 354,

  // minimal drawer size on which the NlLr title should be short
  shortNlLrTitleTrigger: 270,
  // minimal drawer size on which the BeFed title should be short
  shortBeFedTitleTrigger: 270, // TODO: POLMONNL-6203 - change as necessary!
  // minimal drawer size on which the BeFlm title should be short
  shortBeFlmTitleTrigger: 270, // TODO: POLMONNL-6203 - change as necessary!

  // Datepicker filter offset
  //                ms     sec  min  hrs  dys  months
  dateFilterOffset: 1000 * 60 * 60 * 24 * 30 * 12,

  // Maximal number of points to show in trendchart
  trendChartMaxPoints: 250,

  // Milliseconds till a request is considered to be timed out
  requestTimeout: 2500,

  // Default timeline items page size
  pageSizeTimeline: 30,

  // This is related to the height of the trend graph
  infiniteScrollOffsetTimeline: 100,

  // Minimal distanse between two highlighted words in a document before a new bookmark is created
  minimalBookmarkDistance: 400,

  // Max. number of recent searches to show before the oldest is removed
  maxRecentSearches: 25,

  // Minimal number of characters needed for a topic
  minimalQueryLength: 1,

  // Maximum length a list name is allowed to have before it is trimmed with ellipsis
  maxListNameLengthBeforeTrim: 50,

  // Types of confidentiality
  confidentialityTypes: ['openbaar', 'besloten', 'default'],

  // Default items to show in a list. If larger a more/less button is shown
  defaultPageSize: {
    stakeholders: 5,
    trendingTopics: 5,
    agendas: 3,
  },

  // Colors used for the trend analysis chart
  chart: {
    pointBackgroundColor: '#38bfa2',
    borderColor: '#38bfa2',
    backgroundColor: '#bce9e0',
  },

  agenda: {
    colors: {
      plenair: '#1f90bb',
      commission: '#4aa04d',
    },
  },

  // Default stakeholder code
  // Warning: this should always be one of the codes configured for
  // the corresponding dashboard below, but does not need to be the first one.
  nlDefaultAnalyticsCode: 'PER',
  euDefaultAnalyticsCode: 'committees',
  nlLrDefaultAnalyticsCode: 'IG',
  beFedDefaultAnalyticsCode: 'IG',
  beFlmDefaultAnalyticsCode: 'IG',

  // Stakeholders codes per dashboard
  // Note: these are in the order they should be in in the dropdown!
  nlAnalyticsCodes: [
    'PER',
    'IG',
    'COM',
    'TT',
    'AAA',
    'JH',
    'IO',
    'EU',
  ],
  euAnalyticsCodes: [
    'committees',
    'referenceCodes',
  ],
  nlLrAnalyticsCodes: [
    'IG',
    'COM',
    'TT',
    'AAA',
    'JH',
    'IO',
    'EU',
  ],
  beFedAnalyticsCodes: [
    'IG',
    'IO',
    'EU',
  ],
  beFlmAnalyticsCodes: [
    'IG',
    'IO',
    'EU',
  ],

  // TODO: document what these are for - they do not seem to be used anywhere?!
  predefinedColors: [
    ['#ff4500', '#ff8c00'], ['#ffd700', '#90ee90'], ['#00ced1', '#1e90ff'], ['#c71585'],
  ],
};
