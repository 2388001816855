/*
 * Store to manage the visibility of items
 */

const state = {
  sidebarVisible: {
    left: false,
    right: false,
  },
  nlDialogFormSavedSearches: false,
  euDialogFormSavedSearches: false,
  nlLrDialogFormSavedSearches: false,
  beFedDialogFormSavedSearches: false,
  beFlmDialogFormSavedSearches: false,
  agendaDialogFormSavedSearches: false,
  dialogConfirmDialog: false,
  supportDialog: false,
  trendAnalyticsExpanded: false,
};

// getters
const getters = {
  sidebarVisible: (state) => state.sidebarVisible,
  nlDialogFormSavedSearchesVisible: (state) => state.nlDialogFormSavedSearches,
  euDialogFormSavedSearchesVisible: (state) => state.euDialogFormSavedSearches,
  nlLrDialogFormSavedSearchesVisible:
    (state) => state.nlLrDialogFormSavedSearches,
  beFedDialogFormSavedSearchesVisible:
    (state) => state.beFedDialogFormSavedSearches,
  beFlmDialogFormSavedSearchesVisible:
    (state) => state.beFlmDialogFormSavedSearches,
  agendaDialogFormSavedSearchesVisible: (state) => state.agendaDialogFormSavedSearches,
  dialogConfirmDialogVisible: (state) => state.dialogConfirmDialog,
  supportDialogVisible: (state) => state.supportDialog,
  trendAnalyticsExpanded: (state) => state.trendAnalyticsExpanded,
};

// actions
const actions = {

  // Sidebars
  hideSidebars({ commit }) {
    commit('setSidebarVisible', { sidebar: 'right', visible: false });
    commit('setSidebarVisible', { sidebar: 'left', visible: false });
  },
  setSidebarVisible({ commit }, args) {
    commit('setSidebarVisible', args);
  },
  toggleSidebarVisible({ commit }, sidebar) {
    commit('toggleSidebarVisible', sidebar);
  },

  // Forms
  // toggleDialogFormSavedSearches({ commit }) {
  //   commit('toggleDialogFormSavedSearchesVisible');
  // },
  nlSetDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('nlSetDialogFormSavedSearchesVisible', visible);
  },
  euSetDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('euSetDialogFormSavedSearchesVisible', visible);
  },
  nlLrSetDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('nlLrSetDialogFormSavedSearchesVisible', visible);
  },
  beFedSetDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('beFedSetDialogFormSavedSearchesVisible', visible);
  },
  beFlmSetDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('beFlmSetDialogFormSavedSearchesVisible', visible);
  },
  agendaSetDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('agendaSetDialogFormSavedSearchesVisible', visible);
  },

  // ConfirmDialog
  toggleConfirmDialog({ commit }) {
    commit('toggleConfirmDialogVisible');
  },
  setConfirmDialogVisible({ commit }, visible) {
    commit('setConfirmDialogVisible', visible);
  },

  // SupportDialog
  toggleSupportDialog({ commit }) {
    commit('toggleSupportDialogVisible');
  },
  setSupportDialogVisible({ commit }, visible) {
    commit('setSupportDialogVisible', visible);
  },

  // trendAnalyticsExpanded
  toggleTrendAnalyticsExpanded({ commit }) {
    commit('toggleTrendAnalyticsExpanded');
  },

};

// mutations
const mutations = {
  toggleSidebarVisible(state, sidebar) {
    state.sidebarVisible[sidebar] = !state.sidebarVisible[sidebar];
  },
  setSidebarVisible(state, args) {
    state.sidebarVisible[args.sidebar] = args.visible;
  },

  // toggleDialogFormSavedSearches(state) {
  //   state.dialogFormSavedSearches = !state.dialogFormSavedSearches;
  // },
  nlSetDialogFormSavedSearchesVisible(state, visible) {
    state.nlDialogFormSavedSearches = visible;
  },
  euSetDialogFormSavedSearchesVisible(state, visible) {
    state.euDialogFormSavedSearches = visible;
  },
  nlLrSetDialogFormSavedSearchesVisible(state, visible) {
    state.nlLrDialogFormSavedSearches = visible;
  },
  beFedSetDialogFormSavedSearchesVisible(state, visible) {
    state.beFedDialogFormSavedSearches = visible;
  },
  beFlmSetDialogFormSavedSearchesVisible(state, visible) {
    state.beFlmDialogFormSavedSearches = visible;
  },
  agendaSetDialogFormSavedSearchesVisible(state, visible) {
    state.agendaDialogFormSavedSearches = visible;
  },

  toggleConfirmDialog(state) {
    state.dialogConfirmDialog = !state.dialogConfirmDialog;
  },
  setConfirmDialogVisible(state, visible) {
    state.dialogConfirmDialog = visible;
  },

  toggleSupportDialog(state) {
    state.supportDialog = !state.supportDialog;
  },
  setSupportDialogVisible(state, visible) {
    state.supportDialog = visible;
  },
  toggleTrendAnalyticsExpanded(state) {
    state.trendAnalyticsExpanded = !state.trendAnalyticsExpanded;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
