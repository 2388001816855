// App specific
import Config from '@/config';
import statisticsAPI from '@/api/document/document-api-statistics';

// initial state
const state = {
  beFlmStatisticsTrend: null,
  beFlmStatisticsStakeholders: [],
  beFlmStatisticsTrendingTopics: [],
};

// getters
const getters = {
  beFlmStatisticsTrend: (state) => state.beFlmStatisticsTrend,
  beFlmStatisticsStakeholders: (state) => state.beFlmStatisticsStakeholders,
  beFlmStatisticsTrendingTopics: (state) => state.beFlmStatisticsTrendingTopics,
};

// actions
const actions = {

  async beFlmGetStatistics({ commit, rootState }, analyticsCode) {
    let code = analyticsCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    // Add statictics grouping to use
    searchParameters.trendsGroupingName = 'beFlmTrendsGrouping';

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('beFlmSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('beFlmAnalyticsCode', code);
    } else {
      code = localStorage.getItem('beFlmAnalyticsCode') || Config.beFlmDefaultAnalyticsCode;
    }

    // TODO: determine what to do with this - use NL, create BE, skip?
    const { data: { data: stakeholdersData } } = await statisticsAPI
      .nlGetStakeholders(searchParameters, code);

    commit('beFlmSetStakeholders', stakeholdersData);

    const responseTrendingTopics = await statisticsAPI.getTrendingTopics(searchParameters);

    commit('beFlmSetTrendingTopics', responseTrendingTopics.data.data);
  },

  async beFlmClearStatistics({ commit }) {
    commit('beFlmClearStatistics');
  },
};

// mutations
const mutations = {
  beFlmSetTrend(state, trend) {
    state.beFlmStatisticsTrend = trend;
  },
  beFlmSetStakeholders(state, stakeholders) {
    state.beFlmStatisticsStakeholders = stakeholders;
  },
  beFlmSetTrendingTopics(state, trendingTopics) {
    state.beFlmStatisticsTrendingTopics = trendingTopics;
  },
  beFlmClearStatistics(state) {
    state.beFlmStatisticsStakeholders = [];
    state.beFlmStatisticsTrendingTopics = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
