// App specific
import Config from '@/config';
import statisticsAPI from '@/api/document/document-api-statistics';

// initial state
const state = {
  beFedStatisticsTrend: null,
  beFedStatisticsStakeholders: [],
  beFedStatisticsTrendingTopics: [],
};

// getters
const getters = {
  beFedStatisticsTrend: (state) => state.beFedStatisticsTrend,
  beFedStatisticsStakeholders: (state) => state.beFedStatisticsStakeholders,
  beFedStatisticsTrendingTopics: (state) => state.beFedStatisticsTrendingTopics,
};

// actions
const actions = {

  async beFedGetStatistics({ commit, rootState }, analyticsCode) {
    let code = analyticsCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    // Add statictics grouping to use
    searchParameters.trendsGroupingName = 'beFedTrendsGrouping';

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('beFedSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('beFedAnalyticsCode', code);
    } else {
      code = localStorage.getItem('beFedAnalyticsCode') || Config.beFedDefaultAnalyticsCode;
    }

    // TODO: determine what to do with this - use NL, create BE, skip?
    const { data: { data: stakeholdersData } } = await statisticsAPI
      .nlGetStakeholders(searchParameters, code);

    commit('beFedSetStakeholders', stakeholdersData);

    const responseTrendingTopics = await statisticsAPI.getTrendingTopics(searchParameters);

    commit('beFedSetTrendingTopics', responseTrendingTopics.data.data);
  },

  async beFedClearStatistics({ commit }) {
    commit('beFedClearStatistics');
  },
};

// mutations
const mutations = {
  beFedSetTrend(state, trend) {
    state.beFedStatisticsTrend = trend;
  },
  beFedSetStakeholders(state, stakeholders) {
    state.beFedStatisticsStakeholders = stakeholders;
  },
  beFedSetTrendingTopics(state, trendingTopics) {
    state.beFedStatisticsTrendingTopics = trendingTopics;
  },
  beFedClearStatistics(state) {
    state.beFedStatisticsStakeholders = [];
    state.beFedStatisticsTrendingTopics = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
