/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import savedSearchesApi from '@/api/document/document-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  beFlmAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  beFlmAllSavedSearches: [],

  beFlmMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  beFlmCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    rssEnabled: false,
    queryDetails: {
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      query: null,
      metadataField: 'metadata',
    },
  },
};

// getters
const getters = {
  beFlmAllRecentSearches: (state) => state.beFlmAllRecentSearches,
  beFlmAllSavedSearches: (state) => state.beFlmAllSavedSearches,
  beFlmCurrentSearch: (state) => state.beFlmCurrentSearch,
  beFlmMaxNumberOfSearches: (state) => state.beFlmMaxNumberOfSearches,
};

// actions
const actions = {
  async beFlmGetAllSavedSearches({ commit }) {
    const response = await savedSearchesApi.getAll({ groupingName: 'beFlmFiltersGrouping' });
    commit('beFlmSetSavedSearches', response.data);
    commit('beFlmMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  async beFlmSaveSearch({ dispatch }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await savedSearchesApi.update(search);
    } else {
      response = await savedSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    dispatch('beFlmGetAllSavedSearches');
    return response;
  },

  async beFlmSaveSearches({ dispatch }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await savedSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      dispatch('beFlmGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async beFlmUpdateSearch({
    state,
    rootState,
    commit,
    dispatch,
  }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('beFlmSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await savedSearchesApi.update(state.beFlmCurrentSearch);

    dispatch('beFlmGetAllSavedSearches');
    return response;
  },

  async beFlmRemoveSearch({ dispatch }, search) {
    const response = await savedSearchesApi.delete(search);
    dispatch('beFlmGetAllSavedSearches');
    return response;
  },

  async beFlmSetCurrentSearch({ commit }, search) {
    commit('beFlmSetCurrentSearch', search);
  },

  beFlmResetCurrentSearch({ commit }) {
    commit('beFlmResetCurrentSearch');
  },

  async beFlmSetRecentSearches({ commit }, searches) {
    commit('beFlmSetRecentSearches', searches);
  },
};

// mutations
const mutations = {
  beFlmSetCurrentSearch(state, search) {
    state.beFlmCurrentSearch = search;
  },
  beFlmSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.beFlmAllSavedSearches = searches;
  },
  beFlmAddSavedSearch(state, search) {
    state.beFlmAllSavedSearches.data.unshift(search);
  },
  beFlmSetRecentSearches(state, searches) {
    state.beFlmAllRecentSearches = searches;
  },
  beFlmMaxNumberOfSearches(state, payload) {
    state.beFlmMaxNumberOfSearches = payload;
  },
  beFlmAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;

    // Add to the top
    state.beFlmAllRecentSearches.unshift(recentSearch);

    if (state.beFlmAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.beFlmAllRecentSearches.splice(-1, 1);
    }
  },
  beFlmSyncCurrentSearch(state, { searchParameters }) {
    state.beFlmCurrentSearch.queryDetails = searchParameters;
    state.beFlmCurrentSearch.queryDetails.query = searchParameters.query;
  },
  beFlmResetCurrentSearch(state) {
    state.beFlmCurrentSearch = {
      id: null,
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
      rssEnabled: false,
      queryDetails: {
        groupPaths: [],
        groupingDetails: {
          name: null,
          version: null,
        },
        committees: [],
        query: null,
        metadataField: 'metadata',
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
