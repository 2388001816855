import { ifAuthenticated } from '../../router/routing_helpers';

const genDetailRoute = (path, code) => ({
  path,
  name: `${code}Document`,
  props: { dashboard: code },
  beforeEnter: ifAuthenticated,
  // Lazy loading document
  component: () => import(/* webpackChunkName: "DocumentDetail" */ './views/DocumentDetail.vue'),
});

const routes = [
  {
    path: '/document/:documentId/:highlightWord?',
    redirect: { name: 'nlDocument' },
  },
];

routes.push(genDetailRoute('/nl/document/:documentId/:highlightWord?', 'nl'));
routes.push(genDetailRoute('/eu/document/:documentId/:highlightWord?', 'eu'));
routes.push(genDetailRoute('/nl-lr/document/:documentId/:highlightWord?', 'nlLr'));
routes.push(genDetailRoute('/be-fed/document/:documentId/:highlightWord?', 'beFed'));
routes.push(genDetailRoute('/be-flm/document/:documentId/:highlightWord?', 'beFlm'));

export default routes;
