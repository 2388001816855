import polpoApi from '@/api/polpoApi';

export default {
  getTrend: async (searchParameters) => polpoApi.post('statistics/trends', searchParameters),
  euGetTrend: async (searchParameters) => polpoApi.post('eu/statistics/trends', searchParameters),
  getTrendingTopics: async (searchParameters) => polpoApi.post('nl/statistics/trending-topics', searchParameters),
  nlGetStakeholders: async (
    searchParameters,
    stakeholderCode,
  ) => polpoApi.post('nl/statistics/stakeholders', {
    ...searchParameters,
    stakeholderCode,
  }),
  euGetStakeholders: async (
    searchParameters,
    analyticsCode,
  ) => polpoApi.post('eu/statistics/stakeholders', {
    ...searchParameters,
    ...{
      analyticsType: analyticsCode,
    },
  }),
  // TODO: POLMONNL-6203 - Determine what to do with stakeholders (CURRENTLY TBD)
  getFrequencies: async (searchParameters) => polpoApi.post('nl/statistics/frequencies', searchParameters),
  getAllNLandLRStatistics: async (searchParameters) => polpoApi.post('debug/search/statistics', searchParameters),
  getAllEUStatistics: async (searchParameters) => polpoApi.post('debug/search/eu-statistics', searchParameters),
};
