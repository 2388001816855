import polpoApi from '@/api/polpoApi';

export default {
  // The timestamp thingies are here due to IE11
  // See: https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache

  getPreferences: async () => polpoApi.get(`users/me/preferences?timestamp=${new Date().getTime()}`),
  updatePreferences: async (preferences) => polpoApi.putRaw('users/me/preferences', preferences),
  getEmailPreferences: async () => polpoApi.get(`users/me/emailconfig?timestamp=${new Date().getTime()}`),
  updateEmailPreferences: async (preferences) => polpoApi.putRaw('users/me/emailconfig', preferences),
  sendWebCalInviteMail: async () => polpoApi.post('users/me/webcal-setup'),

  getAgendaFiltersForUser: async () => polpoApi.get(`users/me/agenda-filter-types?timestamp=${new Date().getTime()}`),
  getStakeholderSourceList: async (locale) => polpoApi.get(`users/me/stakeholder-source-list?locale=${locale}&timestamp=${new Date().getTime()}`),

  getNlGroupingForUser: async () => polpoApi.get(`nl/filters/nl?timestamp=${new Date().getTime()}`),
  getNlLrGroupingForUser: async () => polpoApi.get(`nl/filters/nl-lr?timestamp=${new Date().getTime()}`),
  getEuGroupingForUser: async () => polpoApi.get(`eu/filters?timestamp=${new Date().getTime()}`),
  getBeFedGroupingForUser: async () => polpoApi.get(`be/filters/be-fed?timestamp=${new Date().getTime()}`),
  getBeFlmGroupingForUser: async () => polpoApi.get(`be/filters/be-flm?timestamp=${new Date().getTime()}`),
};
