// Import the logo images
import logoPolpoLightEnglish from '@/assets/logo-transparent-english-small.png';
import logoPolpoDark from '@/assets/polpo-logo-dark.png';
import logoPolpoLight from '@/assets/polpo-logo-light.png';

export const selectLogo = (locale, isThemeDark) => {
  if (locale === 'en-gb' && !isThemeDark) {
    return logoPolpoLightEnglish;
  }
  // TODO: add English Dark mode logo!
  if (isThemeDark) {
    return logoPolpoDark;
  }
  return logoPolpoLight;
};

export default {
  selectLogo,
};
