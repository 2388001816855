/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import savedSearchesApi from '@/api/document/document-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  nlLrAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  nlLrAllSavedSearches: [],

  nlLrMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  nlLrCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    rssEnabled: false,
    queryDetails: {
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      committees: [], // TODO: remove this EU-only field?
      query: null,
      metadataField: 'metadata',
    },
  },
};

// getters
const getters = {
  nlLrAllRecentSearches: (state) => state.nlLrAllRecentSearches,
  nlLrAllSavedSearches: (state) => state.nlLrAllSavedSearches,
  nlLrCurrentSearch: (state) => state.nlLrCurrentSearch,
  nlLrMaxNumberOfSearches: (state) => state.nlLrMaxNumberOfSearches,
};

// actions
const actions = {
  async nlLrGetAllSavedSearches({ commit }) {
    const response = await savedSearchesApi.getAll({ groupingName: 'nlLrFiltersGrouping' });
    commit('nlLrSetSavedSearches', response.data);
    commit('nlLrMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  async nlLrSaveSearch(_, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await savedSearchesApi.update(search);
    } else {
      response = await savedSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('nlLrGetAllSavedSearches');
    return response;
  },

  async nlLrSaveSearches(_, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await savedSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('nlLrGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async nlLrUpdateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('nlLrSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await savedSearchesApi.update(state.nlLrCurrentSearch);

    this.dispatch('nlLrGetAllSavedSearches');
    return response;
  },

  async nlLrRemoveSearch(_, search) {
    const response = await savedSearchesApi.delete(search);
    this.dispatch('nlLrGetAllSavedSearches');
    return response;
  },

  async nlLrSetCurrentSearch({ commit }, search) {
    commit('nlLrSetCurrentSearch', search);
  },

  nlLrResetCurrentSearch({ commit }) {
    commit('nlLrResetCurrentSearch');
  },

  async nlLrSetRecentSearches({ commit }, searches) {
    commit('nlLrSetRecentSearches', searches);
  },
};

// mutations
const mutations = {
  nlLrSetCurrentSearch(state, search) {
    state.nlLrCurrentSearch = search;
  },
  nlLrSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.nlLrAllSavedSearches = searches;
  },
  nlLrAddSavedSearch(state, search) {
    state.nlLrAllSavedSearches.data.unshift(search);
  },
  nlLrSetRecentSearches(state, searches) {
    state.nlLrAllRecentSearches = searches;
  },
  nlLrMaxNumberOfSearches(state, payload) {
    state.nlLrMaxNumberOfSearches = payload;
  },
  nlLrAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;

    // Add to the top
    state.nlLrAllRecentSearches.unshift(recentSearch);

    if (state.nlLrAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.nlLrAllRecentSearches.splice(-1, 1);
    }
  },
  nlLrSyncCurrentSearch(state, { searchParameters }) {
    state.nlLrCurrentSearch.queryDetails = searchParameters;
    state.nlLrCurrentSearch.queryDetails.query = searchParameters.query;
  },
  nlLrResetCurrentSearch(state) {
    state.nlLrCurrentSearch = {
      id: null,
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
      rssEnabled: false,
      queryDetails: {
        groupPaths: [],
        groupingDetails: {
          name: null,
          version: null,
        },
        committees: [],
        query: null,
        metadataField: 'metadata',
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
