import documentApi from '@/api/document/document-api';

// initial state
const state = {
  beFedDocument: null,
  beFedDocumentRelated: null, // TODO: POLMONNL-6203 - Remove the call to this so it can be removed
};

// getters
const getters = {
  beFedDocument: (state) => state.beFedDocument,
  beFedDocumentRelated: (state) => state.beFedDocumentRelated, // TODO: POLMONNL-6203 - Remove the call to this so it can be removed
};

// actions
const actions = {
  async beFedGetDocumentById({ commit }, args) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const document = await documentApi.getById(args.id, { highlightWord: args.highlightWord });
    commit('beFedSetDocument', document.data);
    return document;
  },

  // TODO: POLMONNL-6203 - Remove the call to this so it can be removed
  async beFedGetRelated({ commit }, refs) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const related = await documentApi.getRelated(refs);
    commit('beFedSetDocumentRelated', related.data);
    return related;
  },
};

// mutations
const mutations = {
  beFedSetDocument(state, document) {
    state.beFedDocument = document;
  },

  beFedSetDocumentRelated(state, related) {
    state.beFedDocumentRelated = related;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
