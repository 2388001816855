import { loginAuthenticate } from '../../router/routing_helpers';

const genDashboardRoute = (path, code) => ({
  path,
  name: `${code}Dashboard`,
  props: { dashboard: code },
  beforeEnter: loginAuthenticate,
  // Lazy loading document
  component: () => import(/* webpackChunkName: "DocumentDashboard" */ './views/DocumentDashboard.vue'),
});

const routes = [
  {
    path: '/',
    redirect: { name: 'nlDashboard' },
  },
  {
    path: '/dashboard',
    redirect: { name: 'nlDashboard' },
  },
];

routes.push(genDashboardRoute('/nl/dashboard', 'nl'));
routes.push(genDashboardRoute('/eu/dashboard', 'eu'));
routes.push(genDashboardRoute('/nl-lr/dashboard', 'nlLr'));
routes.push(genDashboardRoute('/be-fed/dashboard', 'beFed'));
routes.push(genDashboardRoute('/be-flm/dashboard', 'beFlm'));

export default routes;
