import Config from '@/config';
import router from '@/router';
import i18n from '@/i18n';
import documentAPI from '@/api/document/document-api';

// initial state
const state = {
  beFedSearchMoreAfter: null,

  beFedTimelineData: {
    data: [],
    countTotal: 0,
    hasMore: false,
  },
  beFedMaxDateRangeTimelineItemsTotal: 0,
};

// getters
const getters = {
  beFedSearchMoreAfter: (state) => state.beFedSearchMoreAfter,
  beFedTimelineItems: (state) => state.beFedTimelineData.data,
  beFedTimelineItemsCountTotal: (state) => state.beFedTimelineData.countTotal,
  beFedTimelineItemsHasMore: (state) => state.beFedSearchMoreAfter
    && (state.beFedTimelineData.data.length < state.beFedTimelineData.countTotal),
  beFedMaxDateRangeTimelineItemsHasMore: (state) => state.beFedNextItemUrl
    && (state.beFedTimelineData.data.length < state
      .beFedMaxDateRangeTimelineItemsTotal),
  beFedMaxDateRangeTimelineItemsTotal: (state) => state
    .beFedMaxDateRangeTimelineItemsTotal,
};

// actions
const actions = {
  beFedSetMaxDateRangeTimelineItemsTotal({ commit }, total) {
    commit('beFedSetMaxDateRangeTimelineItemsTotal', total);
  },

  beFedSetSearchMoreAfter({ commit }, url) {
    commit('beFedSetSearchMoreAfter', url);
  },

  // Although getEntityByUrl is asynchronous, it needs to be fired synchronous since the
  // items are sorted by time.
  async beFedGetAllTimelineItemsNext({ state, commit, rootState }) {
    // Check if beFedSearchMoreAfter is available
    if (state.beFedSearchMoreAfter) {
      const { searchParameters } = rootState;
      const response = await documentAPI.getTimelineItems(
        { ...searchParameters, searchAfter: state.beFedSearchMoreAfter },
      );
      if (!response) {
        commit('setMessage', {
          message: i18n.t('errorMessage.unknownProblemContactUs'),
          type: 'info',
        });
        return;
      }
      if (response.data.countTotal > 0 && response.data.searchMoreAfter) {
        commit('beFedSetSearchMoreAfter', response.data.searchMoreAfter);
        commit('beFedAppendTimelineItems', response.data);
      }
    }
  },

  async beFedGetAllTimelineItems({ commit, rootState }, skipHistory) {
    // Lets see how many times we have been called
    const { searchParameters } = rootState;
    const {
      query,
      groupPaths,
      groupingDetails,
      committees,
    } = searchParameters;

    commit('beFedClearTimelineItems');

    // Reset all errors
    commit('clearMessages');

    // Set lookBack to false to avoid button will be drawn when switching to
    // other date range
    this.dispatch('setLookBack', false);

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: i18n.t('generic.specifySubject'),
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a query has been set.
    if (query.length < Config.minimalQueryLength) {
      commit('setMessage', {
        message: i18n.t('timeline.subjectTooShortWarning', { minimalQueryLength: Config.minimalQueryLength }),
        type: 'info',
      }, { root: true });

      return;
    }

    // Remove any errors shown
    commit('clearMessages');

    // Mockupdata disable
    if (router.currentRoute.name === 'beFedDashboard' && !skipHistory) {
      // Add query to recent searches
      commit('beFedAddRecentSearch', {
        queryDetails: {
          query,
          groupPaths,
          groupingDetails,
          committees, // TODO: determine if this is necessary for BE FED
        },
      }, { root: true });
      // Set current search to null so none of the saved searches are shown as the active search
      commit('beFedResetCurrentSearch');
    }

    const response = await documentAPI.getTimelineItems(
      { ...searchParameters, includeFullHistoryCount: true },
    );
    if (!response) {
      commit('setMessage', {
        message: i18n.t('errorMessage.unknownProblemContactUs'),
        type: 'info',
      });
      return;
    }

    commit('beFedSetTimelineItems', response.data);

    if (response.data.searchMoreAfter) {
      commit('beFedSetSearchMoreAfter', response.data.searchMoreAfter);
    }
    if (response.data.fullHistoryCount) {
      commit('beFedSetMaxDateRangeTimelineItemsTotal', response.data.fullHistoryCount);
    }

    // Fetch trend, stakeholders and trending topics
    this.dispatch('beFedGetStatistics');

    if (response.data.countTotal === 0) {
      this.dispatch('setLookBack', true);
      commit('setMessage', {
        message: i18n.t('generic.noResultsFound'),
        type: 'info',
      });
    }
  },

  beFedClearTimelineItems({ commit }) {
    commit('beFedClearTimelineItems');
  },
};

// mutations
const mutations = {
  beFedSetMaxDateRangeTimelineItemsTotal(state, total) {
    state.beFedMaxDateRangeTimelineItemsTotal = total;
  },

  beFedSetSearchMoreAfter(state, beFedSearchMoreAfter) {
    state.beFedSearchMoreAfter = beFedSearchMoreAfter;
  },

  beFedSetTimelineItems(state, timelineData) {
    state.beFedTimelineData = timelineData;
    state.beFedTimelineData
      .hasMore = !!((timelineData.actions && timelineData.actions.more));
  },

  beFedAppendTimelineItems(state, timelineData) {
    state.beFedTimelineData.data = [...state
      .beFedTimelineData.data, ...timelineData.data];
  },

  beFedClearTimelineItems(state) {
    state.beFedTimelineData = {
      data: [],
      countTotal: 0,
      hasMore: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
