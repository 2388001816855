// App specific
import Config from '@/config';
import statisticsAPI from '@/api/document/document-api-statistics';

// initial state
const state = {
  euStatisticsTrend: null,
  euStatisticsStakeholders: [],
};

// getters
const getters = {
  euStatisticsTrend: (state) => state.euStatisticsTrend,
  euStatisticsStakeholders: (state) => state.euStatisticsStakeholders,
};

// actions
const actions = {

  async euGetStatistics({ commit, rootState }, analyticsCode) {
    let code = analyticsCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    const responseTrend = await statisticsAPI.euGetTrend(searchParameters);
    commit('euSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('analyticsCode', code);
    } else {
      code = localStorage.getItem('analyticsCode') || Config.euDefaultAnalyticsCode;
    }

    const responseStakeholders = await statisticsAPI
      .euGetStakeholders(searchParameters, code);

    // Why is this setStakeholders?
    commit('euSetStakeholders', responseStakeholders.data.data);
  },

  async euClearStatistics({ commit }) {
    commit('euClearStatistics');
  },

  async getAllEUStatistics({ rootState }) {
    const { searchParameters } = rootState;
    return statisticsAPI.getAllEUStatistics(searchParameters);
  },
};

// mutations
const mutations = {
  euSetTrend(state, trend) {
    state.euStatisticsTrend = trend;
  },
  euSetStakeholders(state, stakeholders) {
    state.euStatisticsStakeholders = stakeholders;
  },
  euClearStatistics(state) {
    state.euStatisticsStakeholders = [];
    state.euStatisticsTrendingTopics = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
