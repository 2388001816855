import documentApi from '@/api/document/document-api';

// initial state
const state = {
  beFlmDocument: null,
  beFlmDocumentRelated: null, // TODO: POLMONNL-6203 - Remove the call to this so it can be removed
};

// getters
const getters = {
  beFlmDocument: (state) => state.beFlmDocument,
  beFlmDocumentRelated: (state) => state.beFlmDocumentRelated, // TODO: POLMONNL-6203 - Remove the call to this so it can be removed
};

// actions
const actions = {
  async beFlmGetDocumentById({ commit }, args) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const document = await documentApi.getById(args.id, { highlightWord: args.highlightWord });
    commit('beFlmSetDocument', document.data);
    return document;
  },

  // TODO: POLMONNL-6203 - Remove the call to this so it can be removed
  async beFlmGetRelated({ commit }, refs) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const related = await documentApi.getRelated(refs);
    commit('beFlmSetDocumentRelated', related.data);
    return related;
  },
};

// mutations
const mutations = {
  beFlmSetDocument(state, document) {
    state.beFlmDocument = document;
  },

  beFlmSetDocumentRelated(state, related) {
    state.beFlmDocumentRelated = related;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
