// App specific
import Config from '@/config';
import statisticsAPI from '@/api/document/document-api-statistics';

// initial state
const state = {
  nlLrStatisticsTrend: null,
  nlLrStatisticsStakeholders: [],
  nlLrStatisticsTrendingTopics: [],
};

// getters
const getters = {
  nlLrStatisticsTrend: (state) => state.nlLrStatisticsTrend,
  nlLrStatisticsStakeholders: (state) => state.nlLrStatisticsStakeholders,
  nlLrStatisticsTrendingTopics: (state) => state.nlLrStatisticsTrendingTopics,
};

// actions
const actions = {

  async nlLrGetStatistics({ commit, rootState }, analyticsCode) {
    let code = analyticsCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    // Add statictics grouping to use
    searchParameters.trendsGroupingName = 'nlLrTrendsGrouping';

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('nlLrSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('nlLrAnalyticsCode', code);
    } else {
      code = localStorage.getItem('nlLrAnalyticsCode') || Config.nlLrDefaultAnalyticsCode;
    }

    const { data: { data: stakeholdersData } } = await statisticsAPI
      .nlGetStakeholders(searchParameters, code);

    commit('nlLrSetStakeholders', stakeholdersData);

    const responseTrendingTopics = await statisticsAPI.getTrendingTopics(searchParameters);

    commit('nlLrSetTrendingTopics', responseTrendingTopics.data.data);
  },

  async nlLrClearStatistics({ commit }) {
    commit('nlLrClearStatistics');
  },
};

// mutations
const mutations = {
  nlLrSetTrend(state, trend) {
    state.nlLrStatisticsTrend = trend;
  },
  nlLrSetStakeholders(state, stakeholders) {
    state.nlLrStatisticsStakeholders = stakeholders;
  },
  nlLrSetTrendingTopics(state, trendingTopics) {
    state.nlLrStatisticsTrendingTopics = trendingTopics;
  },
  nlLrClearStatistics(state) {
    state.nlLrStatisticsStakeholders = [];
    state.nlLrStatisticsTrendingTopics = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
