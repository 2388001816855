/*
 * Store to manage all search parameters
 */

import Config from '@/config';

// Returns an ISO date string with today minus the configurated offset
function defaultStartDateISO() {
  const startDate = new Date(
    (new Date()).getTime() - Config.dateFilterOffset, // today - offset
  );
  startDate.setHours(0, 0, 0, 0); // ... but end of day
  return startDate.toISOString();
}

const defaultEndDateISO = () => {
  const endDate = new Date(); // now
  endDate.setHours(23, 59, 59, 999); // ... but end of day
  return endDate.toISOString();
};

// initial state
const state = {
  query: '',
  startDate: defaultStartDateISO(),
  endDate: defaultEndDateISO(),
  context: [],
  groupingDetails: {
    name: null,
    version: null,
  },
  groupPaths: [],
  euEpCommittees: [],

  minimalMatchPercentage: parseInt(localStorage.getItem('minimalMatchPercentage'), 10) || Config.defaultMinimalMatchPercentage,
  size: Config.pageSizeTimeline,
  agendaFilters: [],
  lookBack: false,
  metadataField: 'metadata',
  locale: JSON.parse(localStorage.getItem('locale')) || 'nl',
};

// getters
const getters = {
  allSearchParameters: (state) => state,
  searchParameterQuery: (state) => state.query,
  searchParameterGroupPaths: (state) => state.groupPaths,
  searchParameterEuEpCommittees: (state) => state.euEpCommittees,
  searchParameterDateRange: (state) => ([state.startDate, state.endDate]),
  searchParameterMinimalMatchPercentage: (state) => state.minimalMatchPercentage,
  lookBack: (state) => state.lookBack,
};

// Helper functions
const setSharedSearchParameters = (commit, searchParameters) => {
  commit('updateQuery', searchParameters.query);
  commit('updateGroupPaths', searchParameters.groupPaths);
  commit('updateDateRange', searchParameters.dateRange);
  // TODO: is this even necessary?
  // commit('updateLocale', searchParameters.locale);
};

const setNlSearchParameters = (commit, searchParameters) => {
  commit('updateContext', searchParameters.context);
  commit('updateMinimalMatchPercentage', searchParameters.minimalMatchPercentage);
};

const setEuSearchParameters = (commit, searchParameters) => {
  commit('updateEuEpCommittees', searchParameters.euEpCommittees);
};

const clearAllSearchParameters = (commit) => {
  commit('updateQuery', '');
  commit('updateGroupPaths', []);
  commit('updateDateRange', {
    startDate: defaultStartDateISO(),
    endDate: defaultEndDateISO(),
  });
  commit('updateContext', []);
  commit(
    'updateMinimalMatchPercentage',
    parseInt(localStorage.getItem('minimalMatchPercentage'), 10)
    || Config.defaultMinimalMatchPercentage,
  );
  commit('updateEuEpCommittees', []);
};

// actions
const actions = {
  setLookBack({ commit }, boolean) {
    commit('setLookBack', boolean);
  },

  setSearchParametersLocale({ commit }, locale) {
    commit('updateLocale', locale);
  },

  nlSetAllSearchParameters({ commit, dispatch }, payload) {
    setSharedSearchParameters(commit, payload);
    setNlSearchParameters(commit, payload);
    dispatch('nlGetAllTimelineItems', true);
  },
  euSetAllSearchParameters({ commit, dispatch }, payload) {
    setSharedSearchParameters(commit, payload);
    setEuSearchParameters(commit, payload);
    dispatch('euGetAllTimelineItems', true);
  },
  nlLrSetAllSearchParameters({ commit, dispatch }, payload) {
    setSharedSearchParameters(commit, payload);
    dispatch('nlLrGetAllTimelineItems', true);
  },
  beFedSetAllSearchParameters({ commit, dispatch }, payload) {
    setSharedSearchParameters(commit, payload);
    dispatch('beFedGetAllTimelineItems', true);
  },
  beFlmSetAllSearchParameters({ commit, dispatch }, payload) {
    setSharedSearchParameters(commit, payload);
    dispatch('beFlmGetAllTimelineItems', true);
  },

  clearAllSearchParameters({ commit }) {
    clearAllSearchParameters(commit);
  },

  setQuery({ commit }, payload) {
    commit('updateQuery', payload);
  },

  setDateRange({ commit }, payload) {
    commit('updateDateRange', payload);
  },

  setContext({ commit }, payload) {
    commit('updateContext', payload);
  },

  setGroupPaths({ commit }, payload) {
    commit('updateGroupPaths', payload);
  },

  setGroupingDetails({ commit }, payload) {
    commit('updateGroupingDetails', payload);
  },

  setEuEpCommittees({ commit }, payload) {
    commit('updateEuEpCommittees', payload);
  },

  setMinimalMatchPercentage({ commit }, payload) {
    commit('updateMinimalMatchPercentage', payload);
  },

  setCheckboxFilter({ commit }, args) {
    commit('setCheckboxFilter', args);
  },
};


// mutations
const mutations = {
  setLookBack(state, boolean) {
    state.lookBack = boolean;
  },

  updateQuery(state, query) {
    state.query = query;
  },

  updateDateRange(state, dateRange) {
    if (dateRange) {
      state.startDate = dateRange.startDate;
      state.endDate = dateRange.endDate;
    }
  },

  updateContext(state, context) {
    state.context = context;
  },

  updateGroupPaths(state, groupPaths) {
    state.groupPaths = groupPaths;
  },

  updateEuEpCommittees(state, commissions) {
    state.euEpCommittees = commissions;
  },

  updateGroupingDetails(state, args) {
    state.groupingDetails = args;
  },

  updateMinimalMatchPercentage(state, percentage) {
    state.minimalMatchPercentage = percentage;
    localStorage.setItem('minimalMatchPercentage', percentage);
  },

  setCheckboxFilter(state, args) {
    state[args.filterKey] = args.filterValue;
  },

  updateLocale(state, locale) {
    state.locale = locale;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
