/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import savedSearchesApi from '@/api/document/document-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  beFedAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  beFedAllSavedSearches: [],

  beFedMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  beFedCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    rssEnabled: false,
    queryDetails: {
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      query: null,
      metadataField: 'metadata',
    },
  },
};

// getters
const getters = {
  beFedAllRecentSearches: (state) => state.beFedAllRecentSearches,
  beFedAllSavedSearches: (state) => state.beFedAllSavedSearches,
  beFedCurrentSearch: (state) => state.beFedCurrentSearch,
  beFedMaxNumberOfSearches: (state) => state.beFedMaxNumberOfSearches,
};

// actions
const actions = {
  async beFedGetAllSavedSearches({ commit }) {
    const response = await savedSearchesApi.getAll({ groupingName: 'beFedFiltersGrouping' });
    commit('beFedSetSavedSearches', response.data);
    commit('beFedMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  async beFedSaveSearch({ dispatch }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await savedSearchesApi.update(search);
    } else {
      response = await savedSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    dispatch('beFedGetAllSavedSearches');
    return response;
  },

  async beFedSaveSearches({ dispatch }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await savedSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      dispatch('beFedGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async beFedUpdateSearch({
    state,
    rootState,
    commit,
    dispatch,
  }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('beFedSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await savedSearchesApi.update(state.beFedCurrentSearch);

    dispatch('beFedGetAllSavedSearches');
    return response;
  },

  async beFedRemoveSearch({ dispatch }, search) {
    const response = await savedSearchesApi.delete(search);
    dispatch('beFedGetAllSavedSearches');
    return response;
  },

  async beFedSetCurrentSearch({ commit }, search) {
    commit('beFedSetCurrentSearch', search);
  },

  beFedResetCurrentSearch({ commit }) {
    commit('beFedResetCurrentSearch');
  },

  async beFedSetRecentSearches({ commit }, searches) {
    commit('beFedSetRecentSearches', searches);
  },
};

// mutations
const mutations = {
  beFedSetCurrentSearch(state, search) {
    state.beFedCurrentSearch = search;
  },
  beFedSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.beFedAllSavedSearches = searches;
  },
  beFedAddSavedSearch(state, search) {
    state.beFedAllSavedSearches.data.unshift(search);
  },
  beFedSetRecentSearches(state, searches) {
    state.beFedAllRecentSearches = searches;
  },
  beFedMaxNumberOfSearches(state, payload) {
    state.beFedMaxNumberOfSearches = payload;
  },
  beFedAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;

    // Add to the top
    state.beFedAllRecentSearches.unshift(recentSearch);

    if (state.beFedAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.beFedAllRecentSearches.splice(-1, 1);
    }
  },
  beFedSyncCurrentSearch(state, { searchParameters }) {
    state.beFedCurrentSearch.queryDetails = searchParameters;
    state.beFedCurrentSearch.queryDetails.query = searchParameters.query;
  },
  beFedResetCurrentSearch(state) {
    state.beFedCurrentSearch = {
      id: null,
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
      rssEnabled: false,
      queryDetails: {
        groupPaths: [],
        groupingDetails: {
          name: null,
          version: null,
        },
        committees: [],
        query: null,
        metadataField: 'metadata',
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
