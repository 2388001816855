import polpoApi from '@/api/polpoApi';

export default {
  // Added timestamp because IE11 is cashing get requests too aggressive
  // See: https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache
  getAll: async (data) => polpoApi.post(`savedsearches/user?timestamp=${new Date().getTime()}`, data),
  save: async (search) => polpoApi.post('savedsearches', search),
  delete: async (data) => polpoApi.delete('savedsearches', data),
  update: async (data) => polpoApi.put('savedsearches', data),
  updates: async (data) => polpoApi.patchRaw('savedsearches/user/', data),
};
