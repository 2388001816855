import Config from '@/config';
import router from '@/router';
import i18n from '@/i18n';
import documentAPI from '@/api/document/document-api';

// initial state
const state = {
  beFlmSearchMoreAfter: null,

  beFlmTimelineData: {
    data: [],
    countTotal: 0,
    hasMore: false,
  },
  beFlmMaxDateRangeTimelineItemsTotal: 0,
};

// getters
const getters = {
  beFlmSearchMoreAfter: (state) => state.beFlmSearchMoreAfter,
  beFlmTimelineItems: (state) => state.beFlmTimelineData.data,
  beFlmTimelineItemsCountTotal: (state) => state.beFlmTimelineData.countTotal,
  beFlmTimelineItemsHasMore: (state) => state.beFlmSearchMoreAfter
    && (state.beFlmTimelineData.data.length < state.beFlmTimelineData.countTotal),
  beFlmMaxDateRangeTimelineItemsHasMore: (state) => state.beFlmNextItemUrl
    && (state.beFlmTimelineData.data.length < state
      .beFlmMaxDateRangeTimelineItemsTotal),
  beFlmMaxDateRangeTimelineItemsTotal: (state) => state
    .beFlmMaxDateRangeTimelineItemsTotal,
};

// actions
const actions = {
  beFlmSetMaxDateRangeTimelineItemsTotal({ commit }, total) {
    commit('beFlmSetMaxDateRangeTimelineItemsTotal', total);
  },

  beFlmSetSearchMoreAfter({ commit }, url) {
    commit('beFlmSetSearchMoreAfter', url);
  },

  // Although getEntityByUrl is asynchronous, it needs to be fired synchronous since the
  // items are sorted by time.
  async beFlmGetAllTimelineItemsNext({ state, commit, rootState }) {
    // Check if beFlmSearchMoreAfter is available
    if (state.beFlmSearchMoreAfter) {
      const { searchParameters } = rootState;
      const response = await documentAPI.getTimelineItems(
        { ...searchParameters, searchAfter: state.beFlmSearchMoreAfter },
      );
      if (!response) {
        commit('setMessage', {
          message: i18n.t('errorMessage.unknownProblemContactUs'),
          type: 'info',
        });
        return;
      }
      if (response.data.countTotal > 0 && response.data.searchMoreAfter) {
        commit('beFlmSetSearchMoreAfter', response.data.searchMoreAfter);
        commit('beFlmAppendTimelineItems', response.data);
      }
    }
  },

  async beFlmGetAllTimelineItems({ commit, rootState }, skipHistory) {
    // Lets see how many times we have been called
    const { searchParameters } = rootState;
    const {
      query,
      groupPaths,
      groupingDetails,
      committees,
    } = searchParameters;

    commit('beFlmClearTimelineItems');

    // Reset all errors
    commit('clearMessages');

    // Set lookBack to false to avoid button will be drawn when switching to
    // other date range
    this.dispatch('setLookBack', false);

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: i18n.t('generic.specifySubject'),
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a query has been set.
    if (query.length < Config.minimalQueryLength) {
      commit('setMessage', {
        message: i18n.t('timeline.subjectTooShortWarning', { minimalQueryLength: Config.minimalQueryLength }),
        type: 'info',
      }, { root: true });

      return;
    }

    // Remove any errors shown
    commit('clearMessages');

    // Mockupdata disable
    if (router.currentRoute.name === 'beFlmDashboard' && !skipHistory) {
      // Add query to recent searches
      commit('beFlmAddRecentSearch', {
        queryDetails: {
          query,
          groupPaths,
          groupingDetails,
          committees, // TODO: determine if this is necessary for BE FLM
        },
      }, { root: true });
      // Set current search to null so none of the saved searches are shown as the active search
      commit('beFlmResetCurrentSearch');
    }

    const response = await documentAPI.getTimelineItems(
      { ...searchParameters, includeFullHistoryCount: true },
    );
    if (!response) {
      commit('setMessage', {
        message: i18n.t('errorMessage.unknownProblemContactUs'),
        type: 'info',
      });
      return;
    }

    commit('beFlmSetTimelineItems', response.data);

    if (response.data.searchMoreAfter) {
      commit('beFlmSetSearchMoreAfter', response.data.searchMoreAfter);
    }
    if (response.data.fullHistoryCount) {
      commit('beFlmSetMaxDateRangeTimelineItemsTotal', response.data.fullHistoryCount);
    }

    // Fetch trend, stakeholders and trending topics
    this.dispatch('beFlmGetStatistics');

    if (response.data.countTotal === 0) {
      this.dispatch('setLookBack', true);
      commit('setMessage', {
        message: i18n.t('generic.noResultsFound'),
        type: 'info',
      });
    }
  },

  beFlmClearTimelineItems({ commit }) {
    commit('beFlmClearTimelineItems');
  },
};

// mutations
const mutations = {
  beFlmSetMaxDateRangeTimelineItemsTotal(state, total) {
    state.beFlmMaxDateRangeTimelineItemsTotal = total;
  },

  beFlmSetSearchMoreAfter(state, beFlmSearchMoreAfter) {
    state.beFlmSearchMoreAfter = beFlmSearchMoreAfter;
  },

  beFlmSetTimelineItems(state, timelineData) {
    state.beFlmTimelineData = timelineData;
    state.beFlmTimelineData
      .hasMore = !!((timelineData.actions && timelineData.actions.more));
  },

  beFlmAppendTimelineItems(state, timelineData) {
    state.beFlmTimelineData.data = [...state
      .beFlmTimelineData.data, ...timelineData.data];
  },

  beFlmClearTimelineItems(state) {
    state.beFlmTimelineData = {
      data: [],
      countTotal: 0,
      hasMore: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
