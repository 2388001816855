import 'babel-polyfill';
import 'promise-polyfill/src/polyfill';
import 'classlist-polyfill';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import AsyncComputed from 'vue-async-computed';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Moment from 'moment';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import config from './config';
import i18n from './i18n';

// The contents of the Interceptors file appends interceptors to the Axios config.
// Therefore, the act of importing it is enough to "use" it.
import './api/interceptors';

Vue.use(AsyncComputed);

Vue.use(VueAxios, axios);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
});

Moment.locale(JSON.parse(localStorage.getItem('locale')) || 'nl');
Vue.prototype.$moment = Moment;

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: process.env.NODE_ENV !== 'production' ? config.googleAnalyticsDevelopment : config.googleAnalyticsProduction,
    params: {
      send_page_view: false,
      anonymize_ip: true,
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
